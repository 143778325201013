@import '../../../styles/variables';

.footer {
  height: 272px;
  padding: 64px;

  &Countries {
    * a {
      color: $primary-brand;
    }
  }
}
