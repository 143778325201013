.Rental {
  width: 54px;
  height: 16px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-size: 12px;
  margin-left: 4px;
  line-height: normal;

  &Short {
    background-color: #93117e;
  }

  &Long {
    background-color: #1a2981;
  }
}
