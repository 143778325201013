// color list https://xd.adobe.com/view/725e3df2-bf0b-45a5-b0cd-3865d690cdad-270d/specs/
$primary-white: #fff;
$primary-brand: #009ee0;
$primary-navy: #1a2981;
$primary-black: #000;

$secondary-blue: #00cdfd;
$secondary-pale-blue: #aceafd;
$secondary-less-blue: #eafaff;
$secondary-dark-blue: #030b42;

$grey-80: #404040;
$grey-70: #4c4c4c;
$grey-60: #666;
$grey-50: #7f7f7f;
$grey-40: #999;
$grey-30: #b2b2b2;
$grey-20: #ccc;
$grey-10: #e5e5e5;
$grey-5: #f2f2f2;

$dark-green: #1f8600;
$green: #34a06e;
$light-green: #c7e1bf;

$dark-berry: #93117e;
$light-ruby: #e4c3df;
$dark-red: #c50c29;
$red: #f05050;
$light-red: #ffd2d1;

$orange: #f17105;
$light-orange: #fdd2af;

$yellow: #ffcc00;
$light-yellow: #ffefad;

$light-blue: #f8f9fa;
$dark-gardient: #030b42;

$black-box-shadow: #03071c24;
