@import '../../../styles/variables';

.vamDialog {
  text-align: center;

  &Title {
    color: $primary-navy;
    font-weight: bold;
    font-size: 16px;
  }

  &SubTitle {
    color: $grey-60;
    font-size: 10px;
  }
}
