@import '../../../styles/variables';

.card {
  &Header {
    @include card-background;
    border-radius: 8px 8px 0px 0px;
    height: 32;
    text-align: center;
    line-height: 3;
  }

  &Content {
    min-height: 302px;
  }
}
