$percents: (
  'w5': 5%,
  'w10': 10%,
  'w15': 15%,
  'w20': 20%,
  'w25': 25%,
  'w30': 30%,
  'w40': 40%,
  'w50': 50%,
  'w60': 60%,
  'w80': 80%,
  'w90': 90%,
  'w100': 100%,
);

@each $class, $value in $percents {
  .#{$class} {
    width: $value;
  }
}
