@import './variables';
@import './global/width';
@import './global/flex';
@import './grid';

.color-black {
  color: $primary-black;
}

.color-navy-primary {
  color: $primary-navy;
}

.color-error-red {
  color: #d32f2f !important;
}

.color-grey-60 {
  color: $grey-60;
}

.color-grey-70 {
  color: $grey-70;
}

.color-white {
  color: $primary-white;
}

.bg-color-light-blue {
  background-color: $light-blue;
}

.bg-color-white {
  background-color: $primary-white !important;
}

.flex-justify-start {
  justify-content: start;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.outline-focus-none {
  &:focus-within {
    outline: none !important;
  }
  &:focus {
    outline: none !important;
  }
}

.hidden {
  display: none !important;
}

ul,
li {
  @apply tw-list-none;
}
