@import './../../styles/variables';

.welcome {
  top: 0px;
  left: 0px;
  height: 576px;
  background: linear-gradient(180deg, #000000e6 0%, #0000001a 10%) 0% 0% no-repeat padding-box,
    url('../../assets/images/img-bg-homepage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;

  &AClass {
    color: $grey-30;
    text-decoration: none;

    &:hover {
      color: $primary-white;
      cursor: pointer;
    }
  }

  &SignIn {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  &WordWideText {
    width: 536px;
  }

  &MyVam {
    padding: 4rem 8rem;
  }

  &VamCard {
    @include vam-breakpoint('xs') {
      width: 100%;
    }

    @include vam-breakpoint('xl') {
      width: 80%;
    }

    @include vam-breakpoint('2xl') {
      width: 60%;
    }
  }
}
