@import './tokens/colors';
@import './tokens/breakpoints';
@import './functions/breakpoints';

// Breakpoints
@mixin vam-breakpoint($device) {
  @if ($device) {
    @media only screen and (min-width: vam-get-breakpoint($device)) {
      @content;
    }
  }
}

@mixin card-background {
  background: transparent linear-gradient(102deg, $primary-navy 0%, #00093f 100%) 0% 0% no-repeat
    padding-box;
  color: $primary-white;
  border-radius: 8px;
}

@mixin button-blue-background {
  background: transparent linear-gradient(100deg, #009ede 0%, #1a2981 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 4px;
  opacity: 1;
}

@mixin button-blue-sky-background {
  background: transparent linear-gradient(95deg, #00cdfb 0%, #009edc 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 4px 8px #00000047;
  border-radius: 4px;
}

@mixin vam-col-5 {
  width: 41.6667%;
}

@mixin vam-col-9 {
  width: 75%;
}
