@import '../../../styles/variables';

.VamFilter {
  @include card-background;
  height: auto;
  margin-top: 16px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0px 2px 4px $black-box-shadow;

  &BlocApply {
    margin-bottom: 6px;
  }

  &Apply {
    background: transparent linear-gradient(95deg, #00cdfb 0%, #009edc 100%) 0% 0% no-repeat
      padding-box !important;
    box-shadow: 0px 4px 8px $black-box-shadow;
    margin: auto !important;
    min-width: 240px !important;

    &:disabled {
      background: $grey-10 !important;
      color: $primary-white;
    }
  }
}
